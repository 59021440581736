<template>
  <div>
    <el-form>
      <title-and-description :field="field" />
    </el-form>
  </div>
</template>

<script>
export default {
  name: "templates-formComponents-DIV",
  components: {
    TitleAndDescription: () => import("./TitleAndDescription"),
  },
  props: ["field"],
  data() {
    return {
      content: "<div></div>",
    };
  },
  mounted() {
    this.field.content = this.content;
  },
};
</script>

<style lang="scss"></style>
